
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';

@Component
export default class Currency extends Vue {
    @Inject(HelperServiceS) private helperService!: HelperService;

    @Prop({
        required: true,
        type: String,
    })
    private currency?: string | null;

    get currencySymbol(): string | null {
        return this.currency ? this.helperService.currencySymbol(this.currency) : null;
    }
}
