
import { Component, Vue, Prop } from 'vue-property-decorator';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import TranslationsService, { TranslationsServiceS } from '@/modules/translations/translations.service';

// @ts-ignore
import type Month from '@/modules/common/types/month.type';
import type Year from '@/modules/common/types/year.type';
import type Day from '@/modules/common/types/day.type';
import { Inject } from 'inversify-props';
import moment from 'moment';
import HorizontalArrows from './ui-kit/horizontal-arrows.vue';

@Component({
    components: {
        HorizontalArrows,
    },
})
export default class DayChanger extends Vue {
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @Inject(TranslationsServiceS) private translationsService!: TranslationsService;

    @Prop({ type: Function, default: null })
    onDayChange!: (day: Day) => void | null;

    get date() {
        const momentDate = moment({
            day: this.day,
            month: this.month,
            year: this.year,
        });

        if (this.$route.path.includes('price-history')) {
            return momentDate.format('MMMM D, YYYY');
        }

        return momentDate.format('dddd, MMMM D, YYYY');
    }

    get day(): Day {
        const day = this.$route.params.day || this.$route.params.historyDay;
        return parseInt(day, 10) as Day;
    }

    get month(): Month {
        return this.documentFiltersService.storeState.settings.month;
    }

    get year(): Year {
        return this.documentFiltersService.storeState.settings.year;
    }

    get dayOfWeek(): number {
        return new Date(this.year, this.month, this.day).getDay();
    }

    beforeMount() {
        window.addEventListener('keydown', this.onKeyDown);
    }

    beforeDestroy() {
        window.removeEventListener('keydown', this.onKeyDown);
    }

    onKeyDown(event: KeyboardEvent) {
        if (event.key === 'ArrowLeft') {
            this.previousDay();
        } else if (event.key === 'ArrowRight') {
            this.nextDay();
        }
    }

    previousDay() {
        if (this.day === 1) {
            if (this.documentFiltersService.storeState.settings.month === 0) {
                this.documentFiltersService.saveMonth(11);
                this.documentFiltersService.saveYear((this.documentFiltersService.storeState.settings.year - 1) as Year);
            } else {
                this.documentFiltersService.saveMonth((this.documentFiltersService.storeState.settings.month - 1) as Month);
            }

            const day = String(this.documentFiltersService.lastDayCurrentMonth);
            this.$router.push({ name: this.$route.name as string, params: { day, historyDay: day } });

            if (this.onDayChange) {
                this.onDayChange(this.documentFiltersService.lastDayCurrentMonth as Day);
            }
        } else {
            const day = String(this.day - 1);
            this.$router.push({ name: this.$route.name as string, params: { day, historyDay: day } });

            if (this.onDayChange) {
                this.onDayChange(this.day as Day);
            }
        }
    }

    nextDay() {
        if (this.day === this.documentFiltersService.lastDayCurrentMonth) {
            if (this.documentFiltersService.storeState.settings.month === 11) {
                this.documentFiltersService.saveMonth(0);
                this.documentFiltersService.saveYear((this.documentFiltersService.storeState.settings.year + 1) as Year);
            } else {
                this.documentFiltersService.saveMonth((this.documentFiltersService.storeState.settings.month + 1) as Month);
            }

            const day = String(1);
            this.$router.push({ name: this.$route.name as string, params: { day, historyDay: day } });

            if (this.onDayChange) {
                this.onDayChange(1);
            }
        } else {
            const day = String(this.day + 1);
            this.$router.push({ name: this.$route.name as string, params: { day, historyDay: day } });

            if (this.onDayChange) {
                this.onDayChange(this.day);
            }
        }
    }
}
