
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
// @ts-ignore
import CustomCascader, { CascaderOptions } from '@/modules/common/components/ui-kit/custom-cascader.vue';
import CarsFiltersService, { CarsFiltersServiceS } from '@/modules/cars/cars-filters.service';
import { COUNTRIES_ANY } from '@/modules/cars/constants/car-filter-types.constant';
import CarsService, { CarsServiceS } from '@/modules/cars/cars.service';
import StyledSearchbar from '@/modules/common/components/styled-searchbar.vue';
import CarsSharedService, { CarsSharedServiceS } from '@/modules/cars/cars-shared.service';

@Component({
    components: { CustomCascader, StyledSearchbar },
})
export default class CountryClusterFilter extends Vue {
    @Inject(CarsFiltersServiceS) private carsFiltersService!: CarsFiltersService;
    @Inject(CarsServiceS) private carsService!: CarsService;
    @Inject(CarsSharedServiceS) private carsSharedService!: CarsSharedService;
    val: any [] = [];

    private query: string | null = null;

    get items(): CascaderOptions[] {
        const { clusters } = this.carsFiltersService;

        if (!clusters) {
            return [];
        }

        let clusterObj = Object.entries(clusters).sort().map(countries => {
            const counter: CascaderOptions = {
                label: countries[0],
                value: countries[0],
                items: Object.entries(countries[1]).map(countryClusters => {
                    const country: CascaderOptions = {
                        label: countryClusters[0],
                        value: countryClusters[0],
                    };
                    return country;
                }),
            };
            return counter;
        });

        clusterObj = [
            clusterObj.find(item => item.value === COUNTRIES_ANY) as CascaderOptions,
            ...clusterObj.filter(item => item.value !== COUNTRIES_ANY),
        ];

        if (this.query) {
            const searchPattern = new RegExp(this.query.toLowerCase());
            clusterObj = clusterObj.filter(clusterIterm => searchPattern.test(clusterIterm.label.toLowerCase()));
        }

        return clusterObj;
    }

    handleChange(query: string) {
        this.query = query;
    }

    get currentValue() {
        return [this.carsService.storeState.settings.country, this.carsService.storeState.settings.cluster];
    }

    set currentValue(value) {
        if (value) {
            const { clusters } = this.carsFiltersService;
            const [country, cluster] = value;
            this.carsSharedService.saveCountry(country);
            this.carsSharedService.saveCluster(cluster);

            if (clusters && country && cluster) {
                const locationId = clusters[`${country}`][`${cluster}`][0] || null;
                this.carsSharedService.savePickUpCity(locationId, this.$route.path);
            }
        }
    }

    get title() {
        return this.currentValue.filter(item => item).join('/');
    }
}
