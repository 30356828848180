
import { Component, Vue } from 'vue-property-decorator';
import { Locale } from 'vue-i18n';
import { Inject } from 'inversify-props';
import HorizontalArrows from '@/modules/common/components/ui-kit/horizontal-arrows.vue';
import type Month from '@/modules/common/types/month.type';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import type Year from '@/modules/common/types/year.type';
import MonthPicker from '@/modules/common/components/ui-kit/month-picker.vue';

@Component({
    components: { MonthPicker, HorizontalArrows },
})
export default class DateDocumentFilter extends Vue {
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;

    changeMonth(diff: number) {
        const date = new Date(this.date);
        date.setMonth(date.getMonth() + diff);

        this.documentFiltersService.saveMonth(date.getMonth() as Month);
        this.documentFiltersService.saveYear(date.getFullYear());
    }

    get locale(): Locale {
        return this.$i18n.locale;
    }

    get date(): Date {
        const { month, year } = this.documentFiltersService.storeState.settings;
        return new Date(year, month, 1);
    }

    set date(date: Date) {
        this.documentFiltersService.saveMonth(date.getMonth() as Month);
        this.documentFiltersService.saveYear(date.getFullYear());
    }
}
