
import { Component, Vue } from 'vue-property-decorator';
import CarRatesAnalysisTable from '@/modules/cars/analysis/table/car-rates-analysis-table.vue';
import CarColorPicker from '@/modules/cars/components/car-color-picker.vue';
import CarCalendar from '@/modules/cars/components/car-calendar.vue';
import CarActions from '@/modules/cars/components/car-actions.vue';
import PageWrapper from '@/modules/common/components/page-wrapper.vue';
import CarHeader from '@/modules/cars/components/car-header.vue';
import CarRatesTooltip from '@/modules/cars/components/car-rates-tooltip.vue';
import CarTogglePrices from '@/modules/cars/components/car-toggle-prices.vue';
import { Inject } from 'inversify-props';
import CarsService, { CarsServiceS } from '@/modules/cars/cars.service';
import ToggleDiff from '@/modules/common/modules/rates/components/toggle-diff.vue';
import CarRatesAnalysisComparison from '../analysis/car-rate-analysis-filter.vue';

@Component({
    components: {
        CarRatesAnalysisTable,
        CarRatesAnalysisComparison,
        CarColorPicker,
        CarCalendar,
        CarActions,
        PageWrapper,
        CarHeader,
        CarRatesTooltip,
        CarTogglePrices,
        ToggleDiff,
    },
})
export default class CarRatesAnalysisTablePage extends Vue {
    @Inject(CarsServiceS)
    readonly carsService!: CarsService;
}
