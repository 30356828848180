
import { Inject } from 'inversify-props';
import { Component, Vue } from 'vue-property-decorator';
import CarsFiltersService, { CarsFiltersServiceS } from '../cars-filters.service';

@Component
export default class CarRatesAnalysisBtn extends Vue {
    @Inject(CarsFiltersServiceS) private carsFiltersService!: CarsFiltersService;

    get isDisabled() {
        return false;
    }

    get currentRouteName() {
        return this.$route.name!;
    }

    get isAnalysisMode() {
        return this.currentRouteName.includes('analysis');
    }

    get ratesRouteName() {
        return this.currentRouteName
            .replace(/\.(analysis|graph-\w+|table)/g, '');
    }

    get analysisRoute() {
        const { params } = this.$route;
        if (!this.isAnalysisMode) {
            return {
                name: 'analysis.table',
                params,
            };
        }

        return {
            name: this.currentRouteName.replace('analysis', ''),
            params,
        };
    }

    get isAnalisysPage() {
        const routeName = this.$route.name;
        return routeName && routeName.includes('analysis');
    }

    public toggleAnalysisMode() {
        if (!this.isAnalisysPage) {
            this.$router
                .push(this.analysisRoute);
            return;
        }
        this.$router.push(this.$route.fullPath.replace('/analysis', ''));
    }
}
