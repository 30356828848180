
import { Component, Vue, Prop } from 'vue-property-decorator';
import type Day from '@/modules/common/types/day.type';
import CalendarItemWrapper from '@/modules/common/components/ui-kit/calendar-item-wrapper.vue';
import { Inject } from 'inversify-props';
import CarsService, { CarsServiceS } from '@/modules/cars/cars.service';
import CalendarEventsContainer from '@/modules/events/components/calendar-events-container.vue';
import CarsFiltersService, { CarsFiltersServiceS } from '@/modules/cars/cars-filters.service';
import Demand from '@/modules/common/components/ui-kit/demand.vue';

@Component({
    components: {
        CalendarItemWrapper,
        CalendarEventsContainer,
        Demand,
    },
})
export default class CarCalendarItemOutRange extends Vue {
    @Inject(CarsServiceS) private carsService!: CarsService;
    @Inject(CarsFiltersServiceS) carsFilterService!: CarsFiltersService;

    @Prop({
        required: true,
        type: Number,
    })
    private day!: Day;

    /**
     * When there is a need to show a demand tooltip, for first-column items we need the card to have
     * z-index higher than left menu bar (otherwise it will be cropped by the menu),
     * but we can't keep it always higher because when the menu expands,
     * the card will overlay it.
     * The solution is to set z-index higher when we need to show the tooltip and rollback it
     * when we don't need to show it.
     */
    public enhancedOverlayMode: boolean = false;
    toggleEnhancedOverlayMode(flag: boolean) {
        // As the tooltip has smooth transition on unmount, we need some delay.
        const delay = flag ? 0 : 100;
        setTimeout(() => {
            this.enhancedOverlayMode = flag;
        }, delay);
    }

    get occupancy(): number {
        return Number(this.carsService.occupancy(this.day));
    }

    get documents() {
        return !!this.carsService.storeState.document;
    }

    get isAvailability() {
        return this.carsFilterService.isAvailability;
    }
}
