
// FIXME I'm pretty sure we should unwrap the <PopupEvents />
//       component here, since theres no any other components here

import EventsManagerService, { EventsManagerServiceS } from '@/modules/events/events-manager.service';
import { Prop, Vue, Component } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import PopupEvents from '@/modules/events/components/popup-events.vue';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';

@Component({
    components: { PopupEvents },
})
export default class PopupEventsContainer extends Vue {
    @Inject(EventsManagerServiceS)
    private eventsManagerService!: EventsManagerService;

    @Inject(DocumentFiltersServiceS)
    private documentFiltersService!: DocumentFiltersService;

    @Prop({ required: true })
    private day!: number;

    get eventsData() {
        const { hotelId } = this.$route.params;

        const { year, month } = this.documentFiltersService;
        const { day } = this;
        const date = new Date(Date.UTC(year, month, day, 0, 0, 0, 0));

        if (hotelId) {
            const collection = this.eventsManagerService.getEventCollection(date, +hotelId);
            const holidays = collection && collection.holiday.length
                ? []
                : this.eventsManagerService.getHolidayEvents(date);
            const events = this.eventsManagerService.getEventsByCollection(collection);

            return [...events, ...holidays];
        }

        return this.eventsManagerService.getEventsByDate(date);
    }
}
