
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import PRICE_SHOWN from '@/modules/rates/constants/price-shown.constant';
import CarsFilterService, { CarsFiltersServiceS } from '@/modules/cars/cars-filters.service';
import CarsService, { CarsServiceS } from '../cars.service';

@Component
export default class CarTogglePrices extends Vue {
    @Inject(CarsServiceS) private carsService!: CarsService;
    @Inject(CarsFiltersServiceS) private carsFilterService!: CarsFilterService;

    @Prop({
        type: Boolean,
        default: false,
    })
    isDisabled!: boolean;

    get options() {
        return [
            { value: PRICE_SHOWN.SHOWN, text: 'Shown', disabled: !this.carsService.isShownPricesAvailable },
            { value: PRICE_SHOWN.NET, text: 'Net' },
            { value: PRICE_SHOWN.TOTAL, text: 'Total' },
            { value: PRICE_SHOWN.CALCULATED, text: 'Calculated', disabled: !this.isAvailableCalculated },
        ];
    }

    get isAvailableCalculated() {
        return this.carsFilterService.settings.extraExpenses.length;
    }

    set priceShown(newValue: PRICE_SHOWN) {
        this.carsService.priceShown = newValue;
    }

    get priceShown(): PRICE_SHOWN {
        return this.carsService.priceShown;
    }
}
