
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import CarsFiltersService, { CarsFiltersServiceS } from '@/modules/cars/cars-filters.service';

@Component
export default class PageWrapper extends Vue {
    @Inject(UserServiceS)
    private userService!: UserService;

    @Inject(CarsFiltersServiceS)
    private carsFilterService!: CarsFiltersService;

    get showCarFooter() {
        if (!this.userService.isCarUser) {
            return false;
        }
        if (!this.carsFilterService.settings.theme) {
            return false;
        }

        return !!this.carsFilterService.settings.theme.logo;
    }
}
