
import CarsDocumentItemModel from '@/modules/cars/models/cars-document-item.model';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import PercentFilter from '@/modules/common/filters/percent.filter';
import MIPriceFilter from '@/modules/common/filters/mi-price.filter';
import type Day from '@/modules/common/types/day.type';
import Percent from '@/modules/common/types/percent.type';
import ASSESSMENTS_TYPES from '@/modules/common/constants/assessments-types.constant';
import CarsService, { CarsServiceS } from '@/modules/cars/cars.service';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import CalendarEventsContainer from '@/modules/events/components/calendar-events-container.vue';
import CalendarItemWrapper from '@/modules/common/components/ui-kit/calendar-item-wrapper.vue';
import Currency from '@/modules/common/components/currency.vue';
import CarsDotDropdown from '@/modules/cars/components/cars-dot-dropdown.vue';
import CarsFiltersService, { CarsFiltersServiceS } from '@/modules/cars/cars-filters.service';
import Demand from '@/modules/common/components/ui-kit/demand.vue';

@Component({
    filters: { PercentFilter, MIPriceFilter },
    components: {
        CarsDotDropdown, CalendarEventsContainer, CalendarItemWrapper, Currency, Demand,
    },
})

export default class CarCalendarItem extends Vue {
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(CarsServiceS) private carsService!: CarsService;
    @Inject(CarsFiltersServiceS) carsFilterService!: CarsFiltersService;

    @Prop({
        required: true,
        type: Number,
    })
    private day!: Day;

    public isRouteLinkActive: boolean = true;

    /**
     * When there is a need to show a demand tooltip, for first-column items we need the card to have
     * z-index higher than left menu bar (otherwise it will be cropped by the menu),
     * but we can't keep it always higher because when the menu expands,
     * the card will overlay it.
     * The solution is to set z-index higher when we need to show the tooltip and rollback it
     * when we don't need to show it.
     */
    public enhancedOverlayMode: boolean = false;
    toggleEnhancedOverlayMode(flag: boolean) {
        // As the tooltip has smooth transition on unmount, we need some delay.
        const delay = flag ? 0 : 100;
        setTimeout(() => {
            this.enhancedOverlayMode = flag;
        }, delay);
    }

    currentPrice(day: Day) {
        const currentCar = this.carsService.currentCar(day) as CarsDocumentItemModel;
        const { currentCompany } = this.userService;

        if (!currentCar || !currentCompany) {
            return null;
        }

        return this.carsService.getCarPrice(currentCar, currentCompany);
    }

    get occupancy(): number | null {
        return Number(this.carsService.occupancy(this.day));
    }

    competitorPercent(day: Day) {
        return this.carsService.competitorPercent(day);
    }

    isCompetitorsSoldOut(day: Day) {
        return this.carsService.isCompetitorsSoldOut(day);
    }

    cardColorClass(percent: Percent | null) {
        const color = this.carsService.getCardAssessment(percent);

        return {
            green: color === ASSESSMENTS_TYPES.GOOD,
            yellow: color === ASSESSMENTS_TYPES.NORMAL,
            red: color === ASSESSMENTS_TYPES.BAD,
            white: this.isCompetitorsSoldOut(this.day),
        };
    }

    get showFleetIndicator() {
        const { branches } = this.carsFilterService;
        if (!branches) {
            return true;
        }
        const { chain } = this.carsService.storeState.settings;

        return !(chain && chain.chainId);
    }

    get isAvgPrice() {
        return this.carsService.storeState.settings.isAvgPrice;
    }

    get positionOfRanking() {
        return this.carsService.positionOfRanking(this.day);
    }

    get isAvailability() {
        return this.carsFilterService.isAvailability;
    }
}
